<template>
    <div class="user-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">更换头像</span></div>
        </div>
        <div class="image-container">
            <el-button class="btn-open-file" type="primary" @click="openFile">选择图片</el-button>
            <input type="file" accept="image/*" multiple ref="inputFile" style="display:none">
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserIconEditView',
    mounted() {
        // let elInputFile = this.$refs.inputFile;
        // elInputFile.click();
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        openFile() {
            let elInputFile = this.$refs.inputFile;
            elInputFile.click();
        }
    }
}
</script>

<style scoped>
    .user-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 3;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .image-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;
        font-size: 4vmin;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-open-file {
        width: 50vmin;
    }

</style>